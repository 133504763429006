import React, { useState } from "react";
import "./StageInput.css";

export default function StageInput() {
  const [hasBorder, setHasBorder] = useState(false);
  const [hasBoxShadow, setHasBoxShadow] = useState(false);
  const [removeOutline, setRemoveOutline] = useState(false);

  const handleBorderChange = (event) => {
    setHasBorder(event.target.checked);
  };

  const handleBoxShadowChange = (event) => {
    setHasBoxShadow(event.target.checked);
  };

  const handleRemoveOutlineChange = (event) => {
    setRemoveOutline(event.target.checked);
  };

  return (
    <div className="container">
      <div className="meta">
        <h1>Input</h1>
        <p>Get text input from the user.</p>
      </div>
      <div className="parent">
        <div className="input">
          <h2>Controls</h2>
          <label>
            <input
              type="checkbox"
              checked={hasBorder}
              onChange={handleBorderChange}
            />
            Apply Border
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              checked={hasBoxShadow}
              onChange={handleBoxShadowChange}
            />
            Apply Box Shadow
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              checked={removeOutline}
              onChange={handleRemoveOutlineChange}
            />
            Remove Outline
          </label>
        </div>
        <div className="output">
          <input
            type="text"
            placeholder="Placeholder…"
            className={`input-field
          ${hasBorder ? "has-border" : ""}
          ${hasBoxShadow ? "has-box-shadow" : ""}
          ${removeOutline ? "remove-outline" : ""}
          `}
          />
        </div>
      </div>
    </div>
  );
}
