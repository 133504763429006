import React, { useState } from "react";
import "./App.css";
import StageInput from "./components/StageInput/StageInput";

function App() {
  // const [hasBoxShadow, setHasBoxShadow] = useState(false);
  // const [hasBorder, setHasBorder] = useState(false);

  // const toggleBoxShadow = () => {
  //   setHasBoxShadow(!hasBoxShadow);
  // };

  // const toggleBorder = () => {
  //   setHasBorder(!hasBorder);
  // };

  return (
    <div className="App">
      <StageInput></StageInput>
      <StageInput></StageInput>
    </div>
  );
}

export default App;
